@import "bootstrap/scss/functions.scss";
@import "bootstrap/scss/variables.scss";

$blue: #17a2b8;
$primary: #EE7C26;

$theme-colors: (
    primary: $primary
);


@import "bootstrap";

body {
    background: #f1f1f1;
}

.btn-primary {
    color: #FFF !important;
}

img {
    max-width: 100%;
}

.logo-container {
    margin-bottom: 1.5rem !important;

    img {
        max-width: 90%;
        margin:0 auto;
        display: block;
    }
}

.box {
    margin: 0 auto;
    padding: 5.5% 15px 15px;

    &.box-sm {
        max-width:350px;
    }
    &.box-md {
        max-width:500px;
    }
    &.box-lg {
        max-width:900px;
    }
}

.no-border-radius{
    border-radius: 0 !important;
}

body.admin {
    .admin-icon {
        width: 24px;
        height: 24px;
    }

    .sidebar {
        border-right: 1px solid var(--grey);
        .nav-link.active {
            background-color: var(--cyan);
            color: var(--white);
        }
    }
}

small, .small {
    font-size: 0.8em;
}
h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
    font-weight: 400;
}
a:not(.btn) {
    text-decoration: none;
}
a:not(.btn):hover {
    text-decoration: underline;
}
.card-body {
    padding: 1.25rem;
}
.text-danger {
    color: #d22020;
}

.otp {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5em;
    padding: 0.5em;

    & > .description {
        flex: 1 1;
        margin: 0;
    }
}

.only-login .factor {
    &:last-child {
        button {
            border: 0;
        }
    }

    &:hover {
        button {
            background: rgba(0,0,0,0.25);
        }
    }

    button {
        border: 0;
        background: 0;
        padding: 1em;
        width: 100%;
        text-align: left;
        border-bottom: 1px solid grey;
    }
}

.top-bar {
  display: flex;

  .btn-group:first-child {
    flex: 1 1;
    justify-content: right;

    & > * {
      flex: 0 0;
    }
  }
}
